

import Vue from "vue"

import { SnackbarInfo } from "@/types/responses"
import { GrowthbookFeatureFlags, VerjiGrowthBook } from "@/utilities/growthbook"

const CreateOrganization = () => import("@/components/CreateOrganization.vue").then(m => m.default || m)
const OrganizationOverview = () => import("@/components/OrganizationOverview.vue").then(m => m.default || m)

export default Vue.extend({
	name: "ManageTenants",
	components: {
        CreateOrganization,
        OrganizationOverview
	},

	data(): {
		loading: boolean
        activeTab: number | null
        tabs: {index: number, name: string}[]
        message: string
        showSnackBar: boolean
        snackBarMessage: SnackbarInfo | null
	} {
		return {
			loading: false,
            activeTab: null,
            tabs: [
                { index: 0, name: 'Organization Overview'},
                { index: 1, name: 'Create New Organization'}
            ],
            message: "",
            showSnackBar: false,
            snackBarMessage: null
		}
	},
    watch: {
        activeTab(val){
            const routeTab = this.$route.query?.tab ?? ""
            if(routeTab !== val){
                this.$router.replace({query:{tab: val}}).catch( () => { console.log("router replaced query")})
            }
        }
    },
   async  beforeCreate() {
        const featureTenantOnboarding = VerjiGrowthBook.getInstance().isOn(GrowthbookFeatureFlags.ManageTenants)
        const isTenantOnboarder = this.$vStore.state.context.anyDomainPermissions.isTenantOnboarder
        
        const isManageTenantsAllowed = featureTenantOnboarding && isTenantOnboarder
        if(!isManageTenantsAllowed ){
            this.$router.push("/")
        }
        console.log("Has Access Tenant-Onboarder", isManageTenantsAllowed)
    },
    async mounted() {

        this.focusTab()
    },
    computed: {
        isCreateTenantFormValid(): boolean {
            const formdata = this.$vStore.state.context.createTenantFormData
            if(formdata.companyName && formdata.businessRegistryId && formdata.email && formdata.phoneNumber){
                return true
            }
            return false
        }
    },
	methods: {
        focusTab(): void {
            const routeTab = this.$route.query?.tab ?? ""
            if(routeTab){
                this.activeTab= +routeTab
            }
            
        },
        changeTab(): void {
            this.activeTab = 0
        },
        updateSnackBar(event: SnackbarInfo){
            console.log("EVENT: ", event)
            this.snackBarMessage = event
            this.showSnackBar = true
        }

	},

})
